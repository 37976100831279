<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import liff from '@line/liff'
import useJwt from '@/auth/jwt/useJwt'
import { isUserLoggedIn } from '@/auth/utils'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    // console.log(this.$route.query)
    if (this.$route.params.invcode) {
      localStorage.setItem('invcode', this.$route.params.invcode)
    }
    if (this.$route.query.ref) {
      localStorage.setItem('invcode', this.$route.query.ref)
    }
  },
  beforeCreate() {
    // linelift
    // this.getProfile()
    // this.$liff.init(data => {})
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    // eslint-disable-next-line no-unused-vars
    // this.routeLine()
    liff.init({ liffId: '1657385644-5q8k432M' }, async () => {
      const currentPath = window.location.pathname
      // console.log(currentPath)
      if (currentPath !== '/Lotterys') {
        if (liff.isLoggedIn()) {
          await liff.getProfile().then(prof => {
            const isLoggedIn = isUserLoggedIn()
            localStorage.setItem('lineProfile', JSON.stringify(prof))
            if (!isLoggedIn) {
              if (!this.$route.params.invcode || !this.$route.query.ref) {
                this.login(prof.userId, prof.userId)
              }
            }
          // else {
          //   this.routeLine()
          // }
          }).catch(err => { console.log(err) })
        } else {
          liff.login()
        }
      }
    }, err => console.error(err.code, err.message))
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  methods: {
    async routeLine() {
      console.log('เข้าแล้ว')
      console.log(this.$route.query)
      if (this.$route.query.deposit) {
        console.log('เข้า else dep')

        this.$router.replace({ name: 'deposit' })
      } else if (this.$route.query.listselect) {
        console.log('เข้า else sele')

        this.$router.replace('/list-select')
      } else if (this.$route.query.withdraw) {
        console.log('เข้า else sewithdrawle')
        this.$router.replace({ name: 'withdraw' })
      } else if (this.$route.query.lotto) {
        console.log('เข้า else lotto')
        this.$router.push('/Lottery')
      } else if (this.$route.query.tang) {
        console.log('เข้า else tang')
        this.$router.push(`/Lottery/tang/${this.$route.query.tang}`)
      } else {
        console.log('เข้า else โปรไฟล์')
        // this.$router.replace({ name: 'profile' })
      }
    },
    async login(user, pass) {
      await useJwt
        .login({
          email: user,
          password: pass,
          Detail: {
            Ip: this.Ip,
            UserAgent: navigator.userAgent,
            Platform: navigator.platform,
          },
        })
        .then(response => {
          const userData = response.data
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
          this.$ability.update(userData.ability)
          this.$router.replace({ name: 'deposit' })
        })
        .catch(error => {
          console.log(error)
          if (!this.$route.params.invcode) {
            this.$router.replace('/register')
          }
        })
    },
  },
}
</script>
